/**
 * @extends WdmSyncObserver
 */
var WdmFrontendSyncObserver = function () {

    this.siteUrl = '/';

    this.syncInProgress = false;

    // Watch for timeouts
    this.abortSyncSeconds = 60;
    this.abortSyncTimeout = null; // Will hold the setTimeout object

    this.lastApiError = null;

    this.states = {

        'CHECK_CONNECTIVITY': {
            percent: 5,
            text: 'Checking connectivity...',
        },

        'LOGIN_FB': {
            percent: 10,
            text: 'Logging in...',
        },

        'LOGIN_WDM': {
            percent: 20,
            text: 'Logging in to Who Deleted Me...',
        },

        'IMPORT_FRIENDS': {
            percent: 35,
            text: 'Importing your previous Who Deleted Me data...',
        },

        'FETCH_FRIENDS': {
            percent: 50,
            text: 'Downloading friends...',
        },

        'FETCH_FRIENDS_SLOW': {
            percent: 50,
            text: 'Downloading friends...',
        },

        'SAVE_FRIENDS': {
            percent: 60,
            text: 'Checking who deleted you...',
        },

        'CHECK_DEACTIVATE': {
            percent: 75,
            text: 'Checking who deactivated...',
        },

        'SAVE_STATUS': {
            percent: 90,
            text: 'Updating friend status...',
        },

        'FINISHED': {
            percent: 95,
            text: this.finalStates[Math.floor(Math.random() * this.finalStates.length)] + '...',
        },
    };

    this.errorMessages = {

        'FB_OFFLINE': {
            title: 'Unable to connect to FB',
            desc: 'Check that you are connected to the internet and try again.',
        },

        'API_OFFLINE': {
            title: 'Unable to connect to Who Deleted Me',
            desc: 'Please try again in a few minutes.',
        },

        'FETCH_FRIENDS_FAILED': {
            title: 'Unable to read your friend list',
            desc: 'Please make sure you\'re logged in and then',
        },

        'TIMEOUT': {
            title: 'Unable to sync your friends',
            desc: 'Sorry, we took too long to check who deleted you.',
        },

        'NO_EXTENSION': {
            title: 'Do you have the Who Deleted Me browser extension?',
            desc: 'You need to install the browser extension to make Who Deleted Me work. <a class="btn btn-blue bn-xs" href="/">Get the extension from the front page</a>',
            recheck: false,
        },

        'NO_FB_COOKIE': {
            title: 'Are you logged in to FB?',
            desc: 'You need to be logged while using Who Deleted Me. Please login and then ',
        },

        'API_ERROR': {
            title: 'Uh-Oh!',
            desc: 'There was a problem saving your friend list.',
        },

    };

    this.recheckButton = '<a class="btn btn-info" href="/app"><i class="glyphicon glyphicon-refresh"></i> Check again</a>';

};

WdmFrontendSyncObserver.prototype = $.extend(new WdmSyncObserver(), {

    /**
     * For changing between screens...
     */
    showSyncComplete: function () {
        $('#syncError').slideUp();
        $('#syncProgress').slideUp();
        if ($('#syncComplete').is(':hidden')) {
            $('#syncComplete').removeClass('hide').hide().slideDown();
        }
    },

    showSyncError: function () {
        $('#syncComplete').slideUp();
        $('#syncProgress').slideUp();
        if ($('#syncError').is(':hidden')) {
            $('#syncError').removeClass('hide').hide().slideDown();
        }
    },

    showSyncProgress: function () {
        $('#syncComplete').slideUp();
        $('#syncError').slideUp();
        if ($('#syncProgress').is(':hidden')) {
            $('#syncProgress').removeClass('hide').hide().slideDown();
        }
    },

    /**
     * The current part of the sync happening has changed
     * @param  {string} state
     * @param  {object} data
     */
    onStateChange: function (state, data) {

        this.showSyncProgress();

        //$('#syncProgress').show().removeClass('hide');

        this.setSyncing();

        var percent, text;

        percent = this.states[state].percent;
        text = this.states[state].text;

        // Custom stuff for special states
        switch (state) {

            case 'FETCH_FRIENDS':
            case 'FETCH_FRIENDS_SLOW':
                text = 'Downloading friends';
                if (data && data.loaded) {
                    text += ' ('+data.loaded+')';
                }

                text += ' (this may take a few seconds)...';

                if (data && data.loaded && data.loaded >= 400) {
                    text += '<br/><small>(This may take longer average than usual because you have lots of friends!)</small>';
                }

                break;

            case 'CHECK_DEACTIVATE':
                if (data) {
                    percent = 75 + (15 / 100 * (data.done / data.total * 100));
                    text = 'Checking who deactivated (' + data.done + ' / ' + data.total + ')...';

                    //if (data.total > 10) {
                    //    text += '<br/><small>(We only need to check this many people the first time!)</small>';
                    //}
                }
                break;

            case 'FINISHED':
                // This is repeated here so a random string is chosen for each sync
                text = this.finalStates[Math.floor(Math.random() * this.finalStates.length)] + '...';
                break;
        }

        $('#syncProgress .status').html(text);

        this.setSyncProgressBar(percent);

    },

    /**
     * Sync has failed
     * @param {string} errorCode
     * @param {object} data
     */
    onFail: function (errorCode, data) {
        this.setNotSyncing();
        this.logFail(errorCode, data);

        if (errorCode == 'TIMEOUT_BACKGROUND' || errorCode == 'TIMEOUT_FRONTEND') {
            errorCode = 'TIMEOUT';
        }

        var errorMessage = this.errorMessages[errorCode];

        html = '<h2>' + errorMessage.title + '</h2>';
        html += '<p>' + errorMessage.desc;

        if (errorCode === 'API_ERROR' && data && data.hasOwnProperty('error')) {
            html += '<br/>';

            if (data.type) {
                html += '<strong>' + data.type + ':</strong> ';
            }

            html += data.error;

        } else if (errorCode === 'API_ERROR' && this.lastApiError) {
            html += '<br/>' + this.lastApiError;
        }

        if (errorMessage.recheck !== false) {
            html += '<br/>' + this.recheckButton;
        }
        html += '</p>';

        // Run Angular compile (for app)
        if (typeof this.compile === 'function') {
            html = this.compile(html);
        }

        $('#syncError').html(html);

        var self = this;

        // Timeouts are just for cosmetic purposes
        setTimeout(function () {

            $('#syncProgress .status').text('Uh-oh!');
            self.setSyncProgressBar(0);

            setTimeout(function () {
                self.showSyncError();
            }, 500);

        }, 500);
    },

    /**
     * Write a log to the console
     * @param  {mixed} data
     * @param  {mixed} moreData
     * @param  {boolean} verbose
     */
    onLog: function (data, moreData, verbose) {
        if (verbose) {
            this.logger.logVerbose(data, moreData);
        } else {
            this.logger.log(data, moreData);
        }

        /**
         * This is an awkward hack because onFail doesn't receive the actual error
         * for API errors, and we can't release the extension. But onLog does get the message
         */
        try {
            if (data === 'API error') {
                this.lastApiError = moreData.status;
                var response = JSON.parse(moreData.responseText);
                if (response.error) {
                    this.lastApiError = moreData.status + ': ' + response.error;
                }
            }
        } catch (e) {
        }
    },

    logFail: function (errorCode, data) {
        // Post error to server for logging
        $.post(this.siteUrl + 'api/error-log', {
            message: this.logger.dumpLog(),
            data: data,
            error: errorCode,
            extVersion: extensionVersion,
        });
    },

    /**
     * Sync has finished (successfully or otherwise)
     * @param  {boolean} success
     * @param  {object} data
     */
    onFinish: function (success, data) {

        this.setNotSyncing();

        if (!$('body').hasClass('route-app')) {
            return false;
        }

        this.onStateChange('FINISHED');
        this.setNotSyncing();

        var self = this;
        setTimeout(function () {

            // Show 100% completion
            $('#syncProgress .status').text('Finished!');
            self.setSyncProgressBar(100);

            $.get('/partials', {
                views: [
                    'current-user',
                    'results',
                    'top-nav',
                ],
            }, function (res) {

                $('#topMiddle').html(res['current-user']);

                $('#topNav').replaceWith(res['top-nav']);
                $('#nav-who-deleted-me').addClass('active');

                $('#syncComplete').html(res.results);

                if ($('#donate-bar').length > 0) {
                    loadDonateBar();
                }

                setTimeout(function () {
                    self.showSyncComplete();
                }, 400);

            });

            /*$('#topMiddle').load('/partials/current-user');

             $.get('/partials/top-nav', function(topNav) {
             $('#topNav').replaceWith(topNav);
             $('#nav-who-deleted-me').addClass('active');
             });

             $('#syncComplete').load('/partials/results', function() {

             setTimeout(function() {
             self.showSyncComplete();
             }, 400);

             });*/

        }, 600);
    },

    /**
     * Additional methods
     */

    setSyncing: function () {
        this.syncInProgress = true;
        clearTimeout(this.abortSyncTimeout);
        this.abortSyncTimeout = null;

        var self = this;

        /**
         * FIXME: Change to abort if no data received after x seconds rather than a fixed about of time from the start.
         *
         * @type {number | Object}
         */
        this.abortSyncTimeout = setTimeout(function () {

            self.onFail('TIMEOUT_FRONTEND');
            self.setNotSyncing();

            if (typeof extensionDebugMessages !== 'undefined') {
                extensionDebugMessages.push({
                    time: new Date().toUTCString(),
                    message: 'abortSyncTimeout from frontend',
                });
            }

        }, this.abortSyncSeconds * 1000);
    },

    setNotSyncing: function () {
        this.syncInProgress = false;
        clearTimeout(this.abortSyncTimeout);
        this.abortSyncTimeout = null;
    },

    setSyncProgressBar: function (percent) {
        $('#syncProgress .progress-bar')
            .css('width', percent + '%')
            .attr('aria-valuenow', percent)
            .find('.sr-only')
            .text(percent + '% Complete');
    },

});
