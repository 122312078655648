/**
 * @param {WdmLogger} logger
 * @param {string} url
 * @param {object} additionalData
 * @constructor
 */
var WdmApi = function (logger, url, additionalData) {
    /**
     * @type {WdmLogger}
     */
    this.logger = logger;

    this.url = url ? url : '/';
    this.additionalData = additionalData ? additionalData : {};
};

WdmApi.prototype = {

    ping: function (callback, errorCallback) {
        this.get('status', {}, callback, errorCallback);
    },

    saveUser: function (user, callback, errorCallback) {
        var data = {
            user: user,
        };
        this.post('user', data, callback, errorCallback);
    },

    updateUser: function (userID, data, callback, errorCallback) {
        this.put('user/' + userID, data, callback, errorCallback);
    },

    saveUserPushToken: function (userID, data, callback, errorCallback) {
        this.post('user/' + userID + '/push-tokens', data, callback, errorCallback);
    },

    deleteUserPushToken: function (platform, token, callback, errorCallback) {
        var data = {
            platform: platform,
            token: token,
        };
        this.delete('push-tokens', data, callback, errorCallback);
    },

    setFriendsDeactivated: function (userID, friends, callback, errorCallback) {
        var data = {
            friends: friends,
        };
        this.post('user/' + userID + '/friends/set-deactivated', data, callback, errorCallback);
    },

    importFriends: function (userID, callback, errorCallback) {
        this.post('user/' + userID + '/import-friends', false, callback, errorCallback);
    },

    saveFriends: function (userID, friends, auto, callback, errorCallback) {
        var data = {
            friendsjson: JSON.stringify(friends),
            auto: auto ? 1 : 0,
        };
        this.post('user/' + userID + '/friends', data, callback, errorCallback);
    },

    get: function (endpoint, data, successCallback, errorCallback) {
        return this._request('GET', endpoint, data, successCallback, errorCallback);
    },

    post: function (endpoint, data, successCallback, errorCallback) {
        return this._request('POST', endpoint, data, successCallback, errorCallback);
    },

    patch: function (endpoint, data, successCallback, errorCallback) {
        return this._request('PATCH', endpoint, data, successCallback, errorCallback);
    },

    put: function (endpoint, data, successCallback, errorCallback) {
        return this._request('PUT', endpoint, data, successCallback, errorCallback);
    },

    delete: function (endpoint, data, successCallback, errorCallback) {
        return this._request('DELETE', endpoint, data, successCallback, errorCallback);
    },

    _request: function (method, endpoint, data, successCallback, errorCallback) {
        var url = this.url + endpoint;

        this.logger.logVerbose(method + ' ' + url, data);

        data = data ? $.extend({}, this.additionalData, data) : this.additionalData;

        // Spoof delete/patch/put requests
        if (method !== 'GET') {
            if (method !== 'POST') {
                data._method = method;
                method = 'POST';
            }
        }

        $.ajax({
            beforeSend: function (jqXHR, settings) {
                jqXHR.url = settings.url;
            },
            data: data,
            error: function (jqXHR, textStatus, errorThrown) {
                var data = {};

                if (jqXHR.hasOwnProperty('responseJSON') && jqXHR.responseJSON) {
                    data = jqXHR.responseJSON;
                } else if (jqXHR.statusText) {
                    data.message = jqXHR.statusText;
                } else {
                    data.message = textStatus;
                }

                errorCallback(data);
            },
            success: successCallback,
            type: method,
            url: url,

        });
    },

};
