var WdmLogger = function () {
    this.verbose = false;

    if (localStorage.getItem('wdmLoggerVerbose') == true) {
        console.log('Enabling verbose logging from saved value.');
        this.verbose = true;
    }

    this.logs = [];
};

WdmLogger.prototype = {

    /**
     * @param verbose
     */
    setVerbose: function (verbose) {
        this.verbose = !!verbose;

        localStorage.setItem('wdmLoggerVerbose', verbose ? 1 : 0);

        if (verbose) {
            console.log('Verbose logging enabled');
        } else {
            console.log('Verbose logging disabled');
        }
    },

    /**
     * Write a message to the console.
     *
     * @param a
     * @param b
     * @param c
     */
    log: function (a, b, c) {
        // Log nothing by default
        if (!this.verbose) {
            return false;
        }

        console.log(new Date().toUTCString(), a, b, c);

        var line = [new Date().toUTCString(), a];

        if (b !== null) {
            line.push(a);
        }

        if (c !== null) {
            line.push(c);
        }

        this.logs.push(line);
    },

    /**
     * @returns {string}
     */
    dumpLog: function () {
        return JSON.stringify(this.logs, null, '\t');
    },

    /**
     * Write a message to the console, only if verbose logging is enabled.
     *
     * @param a
     * @param b
     * @param c
     */
    logVerbose: function (a, b, c) {
        if (this.verbose) {
            this.log(a, b, c);
        }
    },
};
