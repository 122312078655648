/**
 * Show/hide classes if user has/doesn't have the extension
 */

// $('.show-if-ext-gt-version').hide();
// $('.show-if-ext-lt-version').hide();

var logger = new WdmLogger();
var syncObserver = new WdmFrontendSyncObserver();
syncObserver.setLogger(logger);

var extensionVersion = null;

function triggerExtensionEvent(eventName, data) {
    var event = new CustomEvent(eventName, {
        detail: data
    });
    document.dispatchEvent(event);
}

var extensionDebugMessages = [];

document.addEventListener('WdmExtDidLoad', function(event) {
    extensionVersion = event.detail.version;

    if (extensionVersion !== null) {
        $('.show-if-ext-gt-version').each(function(){
            var minVersion = $(this).attr('data-version');
            if (versionCompare(extensionVersion, minVersion) >= 0) {
                $(this).show();
            }
        });

        $('.hide-if-ext-gt-version').each(function(){
            var minVersion = $(this).attr('data-version');
            if (versionCompare(extensionVersion, minVersion) >= 0) {
                $(this).hide();
            }
        });

        $('.show-if-ext-lt-version').each(function(){
            var maxVersion = $(this).attr('data-version');
            if (versionCompare(extensionVersion, maxVersion) < 0) {
                $(this).show();
            }
        });

        $('.hide-if-ext-lt-version').each(function(){
            var minVersion = $(this).attr('data-version');
            if (versionCompare(extensionVersion, minVersion) < 0) {
                $(this).hide();
            }
        });
    }

    // Show upgrade notification
    if (!extensionVersion || versionCompare(extensionVersion, '0.2.15') < 0) {
        var n = '<div class="container"><div class="row"><div class="bs-callout bs-callout-danger outdated-notification-alert">';
        n += '<h4 class="niceFont">Your Who Deleted Me extension is out of date!</h4>';
        n += 'You\'re using an old version of the Who Deleted Me extension (' + extensionVersion + ') which may not work correctly. Update to the latest version (' + latestExtensionVersion + ') to take advantage of bug fixes and new features. <a href="/help#update" class="alert-link">Click here for instructions on how to update.</a>';
        n += '</div></div></div>';
        $('#topBar').after(n);
    }
});
