/**
 * Handles events from the syncer and takes appropriate action.
 * (To abstract differences between extension and app)
 */
var WdmSyncObserver = function (logger) {
    /**
     * @type {WdmLogger}
     */
    this.logger = logger;
};

WdmSyncObserver.prototype = {

    finalStates: [
        'Reticulating splines',
        'Testing your patience',
        'Spinning up hamsters',
        'Programming flux capacitor',
        'Loading humorous message',
        'Warming up Large Hadron Collider',
        'Walking the dog',
        'You still there?',
        'Calculating meaning of life',
        'Entering the matrix',
        'Contemplating',
        'Have a good day',
        'Baking cookies',
        'Computing chance of success',
        'Dividing by zero',
        '//TODO make more funny messages',
        'Consulting the manual',
        'Help, I\'m trapped in a progress bar',
    ],

    setLogger: function (logger) {
        this.logger = logger;
    },

    /**
     * Write a log to the console
     * @param  {mixed} data
     * @param  {mixed} moreData
     * @param  {boolean} verbose
     */
    onLog: function (data, moreData, verbose) {
        if (verbose) {
            this.logger.logVerbose(data, moreData);
        } else {
            this.logger.log(data, moreData);
        }
    },

    /**
     * The current part of the sync happening has changed
     * @param  {string} state
     * @param  {object} data
     */
    onStateChange: function (state, data) {

    },

    /**
     * Sync has failed
     * @param  {string} errorCode
     */
    onFail: function (errorCode) {

    },

    /**
     * Sync has finished (successfully or otherwise)
     * @param  {boolean} success
     * @param  {object} data
     */
    onFinish: function (success, data) {
        this.log = [];
    },

};
